import React from "react";
import PropTypes from "prop-types";

import NavigationContainer from "./NavigationContainer";
import FooterContainer from "./FooterContainer";

import "../scss/Custom.scss";

const LayoutContainer = ({ children }) => {
  return (
    <div id="container-fluid" className="d-flex flex-column">
      <NavigationContainer />

      {children}

      <FooterContainer />
    </div>
  );
};

LayoutContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LayoutContainer;
