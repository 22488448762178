import React from "react";
import PropTypes from "prop-types";

import StandardMenuPagesWithSearch from "./StandardMenuPagesWithSearch";
import ComponentMissing from "../../Debug/ComponentMissing";

const MenuPagesWithSearchSwitcher = ({
  properties: {
    layout,
    primaryLink,
    primaryLinkType,
    secondaryLink,
    secondaryLinkType,
    logo,
    topLinks,
    searchLabel,
    searchListUrl,
    searchPlaceholder,
    navigation,
    languages,
    activeLanguage,
  },
}) => {
  const properties = {
    primaryLink,
    primaryLinkType,
    secondaryLink,
    secondaryLinkType,
    logo,
    topLinks,
    searchLabel,
    searchListUrl,
    searchPlaceholder,
    navigation,
    languages,
    activeLanguage,
  };

  function switchMenupagesWithSearch() {
    switch (layout) {
      case "standard":
        return <StandardMenuPagesWithSearch properties={properties} />;
      default:
        return <ComponentMissing type="MenuPagesWithSearch" subType={layout} />;
    }
  }

  return switchMenupagesWithSearch();
};

MenuPagesWithSearchSwitcher.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default MenuPagesWithSearchSwitcher;
