import React, { useState, useEffect, useCallback, useContext, useRef } from "react";
import { useLocation } from "react-router-dom";

import TranslationContext from "../Context/Translation";
import NavigationSwitcher from "../Components/Navigation/NavigationSwitcher";

import { REACT_APP_TOP_NAVIGATION } from "../Constants/APIConstants";

import { getApi } from "../Utils/ApiUtils";

const NavigationContainer = () => {
  const { translation } = useContext(TranslationContext);
  const currentTranslation = useRef(null);

  const [isNavigationLoaded, setNavigationLoaded] = useState(false);
  const [navigation, setNavigation] = useState(false);

  const location = useLocation();

  const loadNavigation = useCallback(async () => {
    const activeTranslation = translation.find((lang) => lang.active === 1);

    const url =
      activeTranslation.link.split("/")[1].length === 2
        ? `/${activeTranslation.link.split("/")[1]}${REACT_APP_TOP_NAVIGATION}`
        : REACT_APP_TOP_NAVIGATION;

    try {
      const { data } = await getApi(url);

      if (data.content?.colPos0?.length) {
        setNavigation(data.content.colPos0[0]);
        setNavigationLoaded(true);
      }
    } catch (error) {
      setNavigationLoaded(false);
    }
  }, [translation]);

  useEffect(() => {
    // without this check it will initially fire twice: first time on initial load when
    // translation.length is 0 and second time when translation will be set in Context
    // also we ensure this way that activeTranslation below will not be undefined
    if (translation.length) {
      const activeTranslation = translation.find((lang) => lang.active === 1);

      // we need to remember the current translation language after we initially set
      // translations in Context
      if (!currentTranslation.current) {
        currentTranslation.current = activeTranslation;
      }

      // AND after current translation language is changed
      if (currentTranslation.current.twoLetterIsoCode !== activeTranslation.twoLetterIsoCode) {
        currentTranslation.current = activeTranslation;

        setNavigationLoaded(false);
      }
    }
  }, [translation]);

  useEffect(() => {
    // without second check it will initially fire twice: first time on initial load when
    // translation.length is 0 and second time when translation will be set in Context and
    // isNavigationLoaded will be reset
    // also we ensure that activeTranslation in loadNavigation() will not be undefined
    if (!isNavigationLoaded && translation.length) {
      loadNavigation();
    }
  }, [translation, isNavigationLoaded, loadNavigation]);

  useEffect(() => {
    if (isNavigationLoaded) {
      const mainContentElement = document.getElementById("main");

      let navigationElement = document.getElementById("navigation").querySelector(".navbar");
      let navigationHeight = navigationElement.offsetHeight;

      mainContentElement.style.paddingTop = `${navigationHeight}px`;

      window.addEventListener("resize", () => {
        navigationElement = document.getElementById("navigation").querySelector(".navbar");

        navigationHeight = navigationElement.offsetHeight;

        mainContentElement.style.paddingTop = `${navigationHeight}px`;
      });
    }
  }, [isNavigationLoaded, location.pathname]);

  const renderNavigation = () => {
    if (!isNavigationLoaded) {
      return <></>;
    }

    if (isNavigationLoaded && navigation) {
      return (
        <NavigationSwitcher
          type={navigation.type}
          appearance={navigation.appearance}
          content={navigation.content}
          languages={translation}
          activeLanguage={translation.find((lang) => lang.active === 1)}
        />
      );
    }

    return <></>;
  };

  return renderNavigation();
};

export default NavigationContainer;
