import React, { useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";

import { Popover } from "bootstrap";

import Preline from "../../Atoms/Preline";
import Headline from "../../Atoms/Headline";
import Text from "../../Atoms/Text";
import Image from "../../Atoms/Image";
import LinkSwitcher from "../../Atoms/Link/LinkSwitcher";

import { ReactComponent as InfoIcon } from "../../../Images/svg/info-circle.svg";

const StandardProductCardsItem = ({ productCard, animation }) => {
  const cardRef = useRef(null);

  useEffect(() => {
    const bulletsIcon = cardRef.current.querySelectorAll(".bullets-icon");

    const initPopover = (element) => {
      // eslint-disable-next-line no-unused-vars
      const popover = new Popover(element, {
        template: `<div class="popover">
                     <div class="popover-arrow"></div>
                     <div class="popover-content">
                       <div class="popover-title">
                         <div class="text-end">
                           <button type="button" class="btn-close"></button>
                         </div>
                         <h5 class="popover-header"></h5>
                       </div>
                       <div class="popover-body"></div>
                     </div>
                   </div>`,
        container: `.product-cards`,
        placement: "top",
        fallbackPlacements: ["top"], // this has to be specified to prevent flip on scroll
        html: true, // this and sanitize are needed to add custom html to template
        sanitize: false,
      });

      element.addEventListener("show.bs.popover", () => {
        bulletsIcon.forEach((el) => {
          Popover.getInstance(el)?.hide();
        });

        // if one popover is open and we open another, the set includes ONLY the currently opened
      });

      element.addEventListener("shown.bs.popover", () => {
        // because of the above we need to setup click listener on "shown" instead of "show"

        Popover.getInstance(element)
          .tip.querySelector(".btn-close")
          .addEventListener("click", () => {
            bulletsIcon.forEach((el) => {
              Popover.getInstance(el)?.hide();
            });
          });
      });
    };

    bulletsIcon.forEach((el) => {
      initPopover(el);
    });

    window.addEventListener("resize", () => {
      bulletsIcon.forEach((el) => {
        Popover.getInstance(el)?.hide();
      });
    });
  }, []);

  const getContent = useCallback((bullet) => {
    let content = "";

    if (bullet.teaser) {
      content += `<p>${bullet.teaser}</p>`;
    }

    if (bullet.link.url) {
      content += `<a class="link link-primary mt-xxsmall" target="${
        bullet.link.target || "_blank"
      }" href="${bullet.link.url || "/"}">${bullet.link.title}</a>`;
    }

    return content;
  }, []);

  const renderBullets = useCallback(() => {
    return productCard.bullets.map((bullet) => {
      return (
        <div
          key={`key_${bullet.header || Math.random()}`}
          className="pt-xsmall pb-xsmall bullets-item"
        >
          {bullet.header}

          {bullet.teaser && (
            <div
              className="bullets-icon"
              title={bullet.header}
              data-bs-content={getContent(bullet)}
            >
              <InfoIcon />
            </div>
          )}
        </div>
      );
    });
  }, [productCard.bullets, getContent]);

  const { cols_xs: colXs, cols_sm: colSm, cols_md: colMd, cols_lg: colLg } = productCard;

  return (
    <div className={`col-xs-${colXs} col-sm-${colSm} col-md-${colMd} col-lg-${colLg} d-flex`}>
      <div ref={cardRef} className={`card w-100 ${productCard.state}`.trim()}>
        <div className="card-image">
          <Image image={productCard.image[0]} />
        </div>

        <div className="card-body">
          <div>
            <Preline preline={productCard.subheader} additionalClasses="color-static" />

            <Headline
              headerType="h3"
              headline={productCard.header}
              additionalClasses="color-static"
            />

            {productCard.teaser && (
              <div className="mt-xsmall">
                <Text textType="text" text={productCard.teaser} additionalClasses="color-static" />
              </div>
            )}
          </div>

          {productCard.bullets.length > 0 && (
            <div className="mt-small">
              <div className="bullets">{renderBullets()}</div>
            </div>
          )}

          {(productCard.primaryLink || productCard.secondaryLink) && (
            <div className="mt-small" data-aos={animation.links.default}>
              <div className="links">
                <LinkSwitcher
                  linkType={productCard.primaryLinkType}
                  link={productCard.primaryLink}
                  additionalClasses="btn-lg rounded-pill"
                />

                <LinkSwitcher
                  linkType={productCard.secondaryLinkType}
                  link={productCard.secondaryLink}
                  additionalClasses="color-static"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

StandardProductCardsItem.propTypes = {
  productCard: PropTypes.instanceOf(Object),
  animation: PropTypes.instanceOf(Object),
};

export default StandardProductCardsItem;
