import React, { useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";

import { Tab } from "bootstrap";

import Preline from "../../Atoms/Preline";
import Headline from "../../Atoms/Headline";
import Text from "../../Atoms/Text";
import LinkSwitcher from "../../Atoms/Link/LinkSwitcher";

import { createDonutChart, createPieChart, getRandomColor } from "../../../Utils/ChartUtils";

const StandardTAC = ({
  properties: {
    header,
    headerType,
    headerDisplayStyle,
    headerLink,
    headerLinkType,
    secondaryLink,
    secondaryLinkType,
    subline,
    teaser,
    charts,
    animation,
  },
}) => {
  const tabsRef = useRef();

  useEffect(() => {
    const amCharts = [];

    charts.forEach((chart, index) => {
      if (chart.type === "pie") {
        amCharts.push(
          createPieChart(
            `chartdiv_${chart.header.replace(/ /g, "_")}_${index}`,
            `legenddiv_${chart.header.replace(/ /g, "_")}_${index}`,
            chart.slices,
            getRandomColor(),
          ),
        );
      }

      if (chart.type === "donout") {
        amCharts.push(
          createDonutChart(
            `chartdiv_${chart.header.replace(/ /g, "_")}_${index}`,
            `legenddiv_${chart.header.replace(/ /g, "_")}_${index}`,
            chart.slices,
            getRandomColor(),
          ),
        );
      }
    });

    // first half of the hack that is needed prevent troubles with legend on tab change
    tabsRef.current.querySelectorAll(".tab-content > div").forEach((el) => {
      el.classList.add("tab-pane");
    });

    tabsRef.current.querySelector(".tab-pane:first-child").classList.add("active");

    if (charts.length > 1) {
      tabsRef.current.querySelector(".nav-link:first-child").classList.add("active");

      tabsRef.current.querySelectorAll(".nav-link").forEach((el) => {
        const tab = new Tab(el);

        el.addEventListener("click", (e) => {
          e.preventDefault();

          // second half of the hack that is needed prevent troubles with legend on tab change
          amCharts.forEach((chart) => {
            chart.invalidateData();
          });

          tab.show();
        });
      });
    }

    return () => amCharts.forEach((amChart) => amChart.dispose());
  }, [charts]);

  const renderTabNav = useCallback(() => {
    return charts?.map((chart) => {
      return (
        <li
          key={`key_${chart.header.replace(/ /g, "_") || Math.random()}`}
          className="nav-item"
          role="presentation"
        >
          <button
            className="nav-link"
            id={`${chart.header.replace(/ /g, "_")}-tab`}
            data-bs-toggle="tab"
            data-bs-target={`#${chart.header.replace(/ /g, "_")}`}
            type="button"
            role="tab"
            aria-controls={chart.header.replace(/ /g, "_")}
          >
            {chart.header}
          </button>
        </li>
      );
    });
  }, [charts]);

  const renderTabContent = useCallback(() => {
    return charts?.map((chart, index) => {
      const chartId = `chartdiv_${chart.header.replace(/ /g, "_")}_${index}`;
      const legendId = `legenddiv_${chart.header.replace(/ /g, "_")}_${index}`;

      return (
        <div
          key={`key_${chart.header.replace(/ /g, "_") || Math.random()}`}
          id={chart.header.replace(/ /g, "_")}
          role="tabpanel"
          aria-labelledby={`${chart.header.replace(/ /g, "_")}-tab`}
        >
          <div className="chart">
            <div id={chartId} className="pie_chart" />
            <div id={legendId} className="pie_chart_legend mt-medium" />
          </div>
        </div>
      );
    });
  }, [charts]);

  return (
    <div className="container teaser-and-chart standard">
      <div className="row align-items-center">
        <div className="col-md-6 order-2 order-md-1">
          <Preline preline={subline} />

          <Headline headerType={headerType} headerStyle={headerDisplayStyle} headline={header} />

          {teaser && (
            <div className="mt-xsmall">
              <Text textType="text" text={teaser} />
            </div>
          )}

          {(headerLink || secondaryLink) && (
            <div className="mt-small" data-aos={animation.links.default}>
              <div className="links">
                <LinkSwitcher
                  linkType={headerLinkType}
                  link={headerLink}
                  additionalClasses="btn-lg rounded-pill"
                />

                <LinkSwitcher linkType={secondaryLinkType} link={secondaryLink} />
              </div>
            </div>
          )}
        </div>

        <div className="col-md-6 order-1 order-md-2 mb-small mb-md-0">
          <div className="tabs" ref={tabsRef}>
            <ul className="nav nav-pills" id="myTab" role="tablist">
              {renderTabNav()}
            </ul>

            <div className="tab-content">{renderTabContent()}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

StandardTAC.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default StandardTAC;
