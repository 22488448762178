import React from "react";
import PropTypes from "prop-types";

import StandardTAV from "./StandardTAV";
import SecondaryTAV from "./SecondaryTAV";
import ComponentMissing from "../../Debug/ComponentMissing";

const TextAndVideoSwitcher = ({
  layout,
  header,
  headerType,
  headerDisplayStyle,
  headerLink,
  headerLinkType,
  secondaryLink,
  secondaryLinkType,
  subline,
  teaser,
  video,
  poster,
  animation,
}) => {
  const properties = {
    header,
    headerType,
    headerDisplayStyle,
    headerLink,
    headerLinkType,
    secondaryLink,
    secondaryLinkType,
    subline,
    teaser,
    video,
    poster,
    animation,
  };

  function switchTextAndVideo() {
    switch (layout) {
      case "standard":
        return <StandardTAV properties={properties} />;
      case "secondary":
        return <SecondaryTAV properties={properties} />;
      default:
        return <ComponentMissing type="TextAndVIdeo" subType={layout} />;
    }
  }

  return switchTextAndVideo();
};

TextAndVideoSwitcher.propTypes = {
  layout: PropTypes.string,
  header: PropTypes.string,
  headerType: PropTypes.string,
  headerDisplayStyle: PropTypes.string,
  headerLink: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  headerLinkType: PropTypes.string,
  secondaryLink: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  secondaryLinkType: PropTypes.string,
  subline: PropTypes.string,
  teaser: PropTypes.string,
  video: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  poster: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  animaton: PropTypes.instanceOf(Object),
};

export default TextAndVideoSwitcher;
