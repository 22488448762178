import React, { useCallback } from "react";
import PropTypes from "prop-types";

// eslint-disable-next-line no-unused-vars
import videojs from "video.js";
import VREPlayer from "videojs-react-enhanced";

import Headline from "../../Atoms/Headline";
import Preline from "../../Atoms/Preline";

import "video.js/dist/video-js.css";

const StandardVideo = ({
  properties: { header, headerType, headerDisplayStyle, subline, video, poster, cols, alignment },
}) => {
  const getClasses = useCallback(() => {
    let classes = "row";

    switch (alignment) {
      case "right":
        classes += " justify-content-end";
        break;
      case "center":
        classes += " justify-content-center";
        break;
      default:
        classes += " justify-content-start";
    }

    return classes;
  }, [alignment]);

  const playerOptions = {
    src: video ? video.url : "",
    controls: true,
    autoplay: false,
  };

  const resources = {
    poster: poster ? poster.publicUrl : "",
  };

  const videojsOptions = {
    fluid: true,
  };

  return (
    <div className="container video standard">
      <div className="row justify-content-center">
        <div className="col-md-10">
          <Preline preline={subline} />

          <Headline headerType={headerType} headerStyle={headerDisplayStyle} headline={header} />
        </div>
      </div>

      {video && (
        <div className="mt-small">
          <div className={getClasses()}>
            <div className={`col-md-${cols}`}>
              <VREPlayer
                playerOptions={playerOptions}
                resources={resources}
                videojsOptions={videojsOptions}
                onReady={() => {}}
                onPlay={() => {}}
                onPause={() => {}}
                onEnded={() => {}}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

StandardVideo.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default StandardVideo;
