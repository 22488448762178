import React, { useCallback } from "react";
import PropTypes from "prop-types";

import Headline from "../../Atoms/Headline";
import Preline from "../../Atoms/Preline";
import Text from "../../Atoms/Text";
import Image from "../../Atoms/Image";

const StandardFactSheet = ({
  properties: {
    header,
    headerType,
    headerDisplayStyle,
    subline,
    teaser,
    facts,
    columnsPerRow,
    cols,
    alignment,
    animation,
  },
}) => {
  const getClasses = useCallback(() => {
    let classes = "row mt-small";

    switch (alignment) {
      case "right":
        classes += " justify-content-end";
        break;
      case "center":
        classes += " justify-content-center";
        break;
      default:
        classes += " justify-content-start";
    }

    return classes;
  }, [alignment]);

  const renderFacts = useCallback(() => {
    return facts.map((fact) => {
      return (
        <div key={`key_${fact.headline || Math.random()}`} className="col fact-sheet-item">
          {fact.image[0] && (
            <div className="mb-xsmall">
              <Image image={fact.image[0]} animation={animation.image.factSheet} />
            </div>
          )}

          <Headline headerType="h5" headline={fact.headline} />

          {fact.info && (
            <div className="mt-xsmall">
              <Text textType="html" text={fact.info} />
            </div>
          )}
        </div>
      );
    });
  }, [facts, animation]);

  const { col_xs: colXs, col_sm: colSm, col_md: colMd, col_lg: colLg } = columnsPerRow;

  return (
    <div className="container fact-sheet standard">
      <div className="row justify-content-center">
        <div className="col-md-10">
          <Preline preline={subline} />

          <Headline headerType={headerType} headerStyle={headerDisplayStyle} headline={header} />

          {teaser && (
            <div className="mt-xsmall">
              <Text textType="text" text={teaser} />
            </div>
          )}
        </div>
      </div>

      {facts.length > 0 && (
        <div className={getClasses()}>
          <div className={`col-md-${cols}`}>
            <div
              className={`row 
                          row-cols-${colXs} 
                          row-cols-sm-${colSm} 
                          row-cols-md-${colMd} 
                          row-cols-lg-${colLg} 
                          g-5`.replace(/\n\s+/g, "")}
            >
              {renderFacts()}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

StandardFactSheet.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default StandardFactSheet;
