import React, { useCallback } from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";

import { ReactComponent as InternalLinkIcon } from "../../../Images/svg/chevron-right.svg";
import { ReactComponent as ExternalLinkIcon } from "../../../Images/svg/arrow-up.svg";

const StandardLink = ({ properties: { linkType, link, additionalClasses, onClick } }) => {
  const getClasses = useCallback(() => {
    let appearance = "link";

    switch (linkType) {
      case "link-primary":
        appearance += " link-primary";
        break;
      case "link-secondary":
        appearance += " link-secondary";
        break;
      case "link-light":
        appearance += " link-light";
        break;
      default:
        appearance += "";
    }

    return appearance;
  }, [linkType]);

  const getStandardLink = useCallback(() => {
    if (link.publicUrl?.includes("http") || link.url?.includes("http")) {
      return (
        <a
          href={link.publicUrl || link.url}
          target={link.target}
          data-sectionid={link.sectionId}
          data-isin={link.isin}
          className={`${getClasses()} ${additionalClasses}`.trim()}
          onClick={onClick}
        >
          {link.title}

          <ExternalLinkIcon />
        </a>
      );
    }

    return (
      <NavLink
        to={link.publicUrl || link.url}
        target={link.target}
        data-sectionid={link.sectionId}
        data-isin={link.isin}
        className={`${getClasses()} ${additionalClasses}`.trim()}
        activeClassName=""
        onClick={onClick}
        // aria-current="false"
      >
        {link.title}

        <InternalLinkIcon />
      </NavLink>
    );
  }, [link, additionalClasses, getClasses, onClick]);

  return link && (link.publicUrl || link.url) ? getStandardLink() : <></>;
};

StandardLink.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default StandardLink;
