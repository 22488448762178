import React, { useEffect, useState, useRef, useCallback } from "react";
import PropTypes from "prop-types";

import SlickSlider from "react-slick";
import { Popover } from "bootstrap";

import Preline from "../../Atoms/Preline";
import Headline from "../../Atoms/Headline";
import Image from "../../Atoms/Image";

const PrevArrow = (buttonProps) => {
  return (
    <span
      className={`carousel-control-prev-icon ${buttonProps.className}`}
      aria-hidden="true"
      onClick={buttonProps.onClick}
    />
  );
};

const NextArrow = (buttonProps) => {
  return (
    <span
      className={`carousel-control-next-icon ${buttonProps.className}`}
      aria-hidden="true"
      onClick={buttonProps.onClick}
    />
  );
};

/* eslint-disable react/jsx-props-no-spreading */
const SliderGallery = ({
  properties: { header, headerType, headerDisplayStyle, subline, id, galleryItems, columnsPerRow },
}) => {
  const sliderRef = useRef(null);

  const [settings, setSettings] = useState({
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: +columnsPerRow.col_lg,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: +columnsPerRow.col_md,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: +columnsPerRow.col_sm,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: +columnsPerRow.col_xs,
          slidesToScroll: 1,
        },
      },
    ],
  });

  useEffect(() => {
    const carouselItems = [...sliderRef.current.querySelectorAll(".slider-item")].filter(
      (carouselItem) => carouselItem.dataset.bsContent,
    );

    const initPopover = (element) => {
      // eslint-disable-next-line no-unused-vars
      const popover = new Popover(element, {
        template: `<div class="popover">
                     <div class="popover-content">
                       <div class="popover-title">
                         <div class="text-end">
                          <button type="button" class="btn-close"></button>
                         </div>
                         <h5 class="popover-header"></h5>
                       </div>
                       <div class="popover-body"></div>
                     </div>
                   </div>`,
        container: `#gallery-slider-${id}`,
        placement: "top",
        fallbackPlacements: ["top"], // this has to be specified to prevent flip on scroll
        // eslint-disable-next-line no-unused-vars
        offset: ({ placement, reference, popper }) => {
          if (placement === "top") {
            return [0, reference.height / -2];
          }
          return [];
        },
        html: true, // this and sanitize are needed to add custom html to template
        sanitize: false,
      });

      element.addEventListener("show.bs.popover", () => {
        carouselItems.forEach((el) => {
          Popover.getInstance(el)?.hide();
        });

        // if one popover is open and we open another, the set includes ONLY the currently opened
      });

      element.addEventListener("shown.bs.popover", () => {
        // because of the above we need to setup click listener on "shown" instead of "show"

        sliderRef.current.querySelector(".btn-close").addEventListener("click", () => {
          carouselItems.forEach((el) => {
            Popover.getInstance(el)?.hide();
          });
        });
      });
    };

    carouselItems.forEach((el) => {
      initPopover(el);
    });

    window.addEventListener("resize", () => {
      carouselItems.forEach((el) => {
        Popover.getInstance(el)?.hide();
      });
    });

    const setup = {
      beforeChange() {
        carouselItems.forEach((el) => {
          Popover.getInstance(el)?.hide();
        });
      },
    };

    setSettings((currentSettings) => ({ ...currentSettings, ...setup }));
  }, [id]);

  const getContent = useCallback((column) => {
    let content = "";

    if (column.properties.description) {
      content += `<p>${column.properties.description}</p>`;
    }

    if (column.properties.link) {
      content += `<a class="link link-primary mt-xxsmall color-static" target="${
        column.properties.link.target || "_blank"
      }" href="${column.properties.link.url || "/"}">${column.properties.link.title}</a>`;
    }

    return content;
  }, []);

  const renderGallery = useCallback(() => {
    return Object.values(galleryItems.columns).map((column) => {
      return (
        <div
          key={`key_${column.properties.title || Math.random()}`}
          className="slider-item"
          title={column.properties.title}
          data-bs-content={getContent(column)}
        >
          <Image image={column} />
        </div>
      );
    });
  }, [galleryItems, getContent]);

  return (
    <div className="container gallery slider">
      <div className="row justify-content-center">
        <div className="col-md-10">
          <Preline preline={subline} />

          <Headline headerType={headerType} headerStyle={headerDisplayStyle} headline={header} />
        </div>
      </div>

      {Object.values(galleryItems.columns).length > 0 && (
        <div className="row mt-small">
          <div className="col-12">
            <div ref={sliderRef} id={`gallery-slider-${id}`}>
              <SlickSlider {...settings}>{renderGallery()}</SlickSlider>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

SliderGallery.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default SliderGallery;
