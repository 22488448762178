import React from "react";
import PropTypes from "prop-types";

import ElementSwitcher from "../../ContentElements/ElementSwitcher";

const NewsDetailsLayout = ({ content }) => {
  const renderElements = () => {
    if (content.colPos0) {
      return <ElementSwitcher contentElements={content.colPos0} />;
    }

    return <></>;
  };

  return renderElements();
};

NewsDetailsLayout.propTypes = {
  content: PropTypes.instanceOf(Object),
};

export default NewsDetailsLayout;
