/* eslint-disable max-len */
import React, { useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";

import { Carousel } from "bootstrap";

import Preline from "../../Atoms/Preline";
import Headline from "../../Atoms/Headline";
import Text from "../../Atoms/Text";
import Image from "../../Atoms/Image";
import LinkSwitcher from "../../Atoms/Link/LinkSwitcher";

const CarouselGallery = ({
  properties: { header, headerType, headerDisplayStyle, subline, id, galleryItems, animation },
}) => {
  const carouselRef = useRef(null);

  useEffect(() => {
    // eslint-disable-next-line no-unused-vars
    const carousel = new Carousel(carouselRef.current);

    carouselRef.current.querySelector(".carousel-item:first-child").classList.add("active");
    carouselRef.current.querySelector(".carousel-indicator:first-child").classList.add("active");
  }, []);

  const renderIndicators = useCallback(() => {
    let index = -1;

    return Object.values(galleryItems.columns).map(() => {
      index += 1;

      return (
        <button
          key={`key_${index}`}
          className="carousel-indicator"
          type="button"
          data-bs-target={`#gallery-carousel-${id}`}
          data-bs-slide-to={index}
          aria-current="true"
          aria-label={`Slide ${index}`}
        />
      );
    });
  }, [galleryItems, id]);

  const renderGallery = useCallback(() => {
    return Object.values(galleryItems.columns).map((column) => {
      return (
        <div key={`key_${column.properties.title || Math.random()}`} className="carousel-item">
          <Image image={column} additionalClasses="d-block w-100" />

          <div className="carousel-caption d-none d-md-block">
            <Preline preline={column.properties.subline} />

            <Headline
              headerType={column.properties.headerType}
              headline={column.properties.title}
            />

            {column.properties.description && (
              <div className="mt-xsmall">
                <Text textType="text" text={column.properties.description} />
              </div>
            )}

            {column.properties.link && (
              <div className="mt-small" data-aos={animation.links.default}>
                <div className="links">
                  <LinkSwitcher
                    linkType={column.properties.linkType}
                    link={column.properties.link}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      );
    });
  }, [galleryItems, animation]);

  return (
    <>
      <div className="container gallery carousel">
        <div className="row justify-content-center">
          <div className="col-md-10">
            <Preline preline={subline} />

            <Headline headerType={headerType} headerStyle={headerDisplayStyle} headline={header} />
          </div>
        </div>

        {Object.values(galleryItems.columns).length > 0 && (
          <div className="row mt-small">
            <div className="col-12">
              <div
                ref={carouselRef}
                id={`gallery-carousel-${id}`}
                className="carousel slide"
                data-bs-ride="carousel"
              >
                <div className="carousel-indicators">{renderIndicators()}</div>

                <div className="carousel-inner">{renderGallery()}</div>

                <button
                  className="carousel-control-prev"
                  type="button"
                  data-bs-target={`#gallery-carousel-${id}`}
                  data-bs-slide="prev"
                >
                  <span className="carousel-control-prev-icon" aria-hidden="true" />
                  <span className="visually-hidden">Previous</span>
                </button>

                <button
                  className="carousel-control-next"
                  type="button"
                  data-bs-target={`#gallery-carousel-${id}`}
                  data-bs-slide="next"
                >
                  <span className="carousel-control-next-icon" aria-hidden="true" />
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

CarouselGallery.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default CarouselGallery;
