import React, { useCallback, useContext } from "react";
import PropTypes from "prop-types";

import TranslationContext from "../../../Context/Translation";

import VerticalNewsListItem from "./VerticalNewsListItem";

const VerticalNewsList = ({ properties: { news, hideDate, animation } }) => {
  const { translation } = useContext(TranslationContext);

  const activeTranslation = translation.find((lang) => lang.active === 1);

  const renderNews = useCallback(() => {
    return news.map((newsItem) => {
      return (
        <VerticalNewsListItem
          news={newsItem}
          key={`key_${newsItem.title || Math.random()}`}
          linkTitle={activeTranslation.twoLetterIsoCode === "de" ? "Weiterlesen" : "Read More"}
          hideDate={hideDate}
          animation={animation}
        />
      );
    });
  }, [news, activeTranslation, hideDate, animation]);

  return (
    <div className="container news-list vertical">
      {news.length > 0 && (
        <div
          className={`row 
                      row-cols-1 
                      g-4`.replace(/\n\s+/g, "")}
        >
          {renderNews()}
        </div>
      )}
    </div>
  );
};

VerticalNewsList.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default VerticalNewsList;
