import React from "react";
import PropTypes from "prop-types";

import StandardGoogleMaps from "./StandardGoogleMaps";
import SecondaryGoogleMaps from "./SecondaryGoogleMaps";
import ComponentMissing from "../../Debug/ComponentMissing";

const { REACT_APP_GOOGLE_MAPS_KEY } = process.env;

const GoogleMapsSwitcher = ({
  layout,
  header,
  headerType,
  headerDisplayStyle,
  subline,
  mapStyle,
  icon,
  markers,
  centerLat,
  centerLng,
  defaultZoom,
}) => {
  const properties = {
    header,
    headerType,
    headerDisplayStyle,
    subline,
    icon,
    markers,
    centerLat,
    centerLng,
    defaultZoom,
  };

  const mapsUrl =
    // eslint-disable-next-line max-len
    `https://maps.googleapis.com/maps/api/js?key=${REACT_APP_GOOGLE_MAPS_KEY}&v=3.exp&libraries=geometry,drawing,places`;

  const defaultStyleOptions = [
    {
      featureType: "administrative",
      elementType: "all",
      stylers: [
        {
          visibility: "on",
        },
        {
          lightness: 33,
        },
      ],
    },
    {
      featureType: "administrative.land_parcel",
      elementType: "all",
      stylers: [
        {
          saturation: "0",
        },
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "landscape",
      elementType: "all",
      stylers: [
        {
          color: "#f5efe8",
        },
      ],
    },
    {
      featureType: "landscape",
      elementType: "geometry",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "landscape.man_made",
      elementType: "geometry",
      stylers: [
        {
          saturation: "1",
        },
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "landscape.natural",
      elementType: "labels.text",
      stylers: [
        {
          saturation: "-6",
        },
      ],
    },
    {
      featureType: "landscape.natural.landcover",
      elementType: "geometry",
      stylers: [
        {
          saturation: "-1",
        },
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "landscape.natural.landcover",
      elementType: "labels.text",
      stylers: [
        {
          saturation: "12",
        },
      ],
    },
    {
      featureType: "landscape.natural.terrain",
      elementType: "all",
      stylers: [
        {
          saturation: "0",
        },
      ],
    },
    {
      featureType: "landscape.natural.terrain",
      elementType: "geometry",
      stylers: [
        {
          saturation: "2",
        },
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "all",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "geometry",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "poi.attraction",
      elementType: "geometry",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "poi.business",
      elementType: "geometry",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "poi.government",
      elementType: "geometry",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "poi.medical",
      elementType: "geometry",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "poi.park",
      elementType: "geometry",
      stylers: [
        {
          color: "#f5efe8",
        },
        {
          saturation: "0",
        },
        {
          lightness: "-4",
        },
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "poi.park",
      elementType: "labels",
      stylers: [
        {
          visibility: "on",
        },
        {
          lightness: 20,
        },
      ],
    },
    {
      featureType: "poi.park",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#cfbaa3",
        },
      ],
    },
    {
      featureType: "poi.place_of_worship",
      elementType: "geometry",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "poi.school",
      elementType: "geometry",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "poi.sports_complex",
      elementType: "all",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "poi.sports_complex",
      elementType: "geometry",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "all",
      stylers: [
        {
          lightness: 20,
        },
      ],
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [
        {
          color: "#83a5b0",
        },
        {
          visibility: "simplified",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "labels",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "on",
        },
        {
          saturation: "-100",
        },
        {
          hue: "#00acff",
        },
      ],
    },
    {
      featureType: "road.highway.controlled_access",
      elementType: "geometry",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "road.highway.controlled_access",
      elementType: "labels",
      stylers: [
        {
          visibility: "on",
        },
        {
          saturation: "-100",
        },
        {
          lightness: "0",
        },
      ],
    },
    {
      featureType: "road.highway.controlled_access",
      elementType: "labels.text",
      stylers: [
        {
          visibility: "on",
        },
        {
          lightness: "21",
        },
      ],
    },
    {
      featureType: "road.highway.controlled_access",
      elementType: "labels.text.fill",
      stylers: [
        {
          saturation: "0",
        },
      ],
    },
    {
      featureType: "road.highway.controlled_access",
      elementType: "labels.icon",
      stylers: [
        {
          saturation: "-9",
        },
        {
          lightness: "5",
        },
        {
          hue: "#ff0000",
        },
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "road.arterial",
      elementType: "geometry",
      stylers: [
        {
          color: "#bdcdd3",
        },
        {
          visibility: "on",
        },
        {
          lightness: "40",
        },
      ],
    },
    {
      featureType: "road.arterial",
      elementType: "labels",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "road.local",
      elementType: "geometry",
      stylers: [
        {
          color: "#ffffff",
        },
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "road.local",
      elementType: "labels",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "all",
      stylers: [
        {
          visibility: "on",
        },
        {
          color: "#86b0c1",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "labels.text",
      stylers: [
        {
          lightness: "62",
        },
      ],
    },
  ];

  const styleOptions = mapStyle || defaultStyleOptions;

  function switchGoogleMaps() {
    switch (layout) {
      case "standard":
        return (
          <StandardGoogleMaps
            properties={properties}
            styleOptions={styleOptions}
            googleMapURL={mapsUrl}
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `400px` }} />}
            mapElement={<div style={{ height: `100%` }} />}
          />
        );
      case "secondary":
        return (
          <SecondaryGoogleMaps
            properties={properties}
            styleOptions={styleOptions}
            googleMapURL={mapsUrl}
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `400px` }} />}
            mapElement={<div style={{ height: `100%` }} />}
          />
        );
      default:
        return <ComponentMissing type="GoogleMaps" subType={layout} />;
    }
  }

  return switchGoogleMaps();
};

GoogleMapsSwitcher.propTypes = {
  layout: PropTypes.string,
  headerType: PropTypes.string,
  headerDisplayStyle: PropTypes.string,
  header: PropTypes.string,
  subline: PropTypes.string,
  defaultZoom: PropTypes.string,
};

export default GoogleMapsSwitcher;
