/* eslint-disable no-script-url */
import React from "react";
import PropTypes from "prop-types";

// eslint-disable-next-line no-unused-vars
import videojs from "video.js";
// eslint-disable-next-line no-unused-vars
import videojsYoutube from "videojs-youtube";
import VREPlayer from "videojs-react-enhanced";
import Cookies from "js-cookie";

import Headline from "../../Atoms/Headline";
import Preline from "../../Atoms/Preline";
import Text from "../../Atoms/Text";
import LinkSwitcher from "../../Atoms/Link/LinkSwitcher";

import { parseCookies } from "../../../Utils/FunctionalUtils";

import "video.js/dist/video-js.css";

const SecondaryTAV = ({
  properties: {
    header,
    headerType,
    headerDisplayStyle,
    headerLink,
    headerLinkType,
    secondaryLink,
    secondaryLinkType,
    subline,
    teaser,
    video,
    poster,
    animation,
  },
}) => {
  const playerOptions = {
    src: video ? video.publicUrl : "",
    controls: true,
    autoplay: false,
  };

  const resources = {
    poster: poster ? poster.publicUrl : "",
    sources:
      video && video.publicUrl.includes("youtube")
        ? { type: "video/youtube", src: video.publicUrl }
        : "",
  };

  const videojsOptions = {
    fluid: true,
  };

  const hasCookiebot = document.getElementById("Cookiebot");
  const parsedCookies = parseCookies(Cookies.get("CookieConsent"));

  return (
    <div className="container text-and-video secondary bg_color_tertiary pt-medium pb-medium">
      <div className="row justify-content-center">
        <div className="col-10 col-xl-4 mb-4 mb-xl-0">
          <Preline preline={subline} />

          <Headline headerType={headerType} headerStyle={headerDisplayStyle} headline={header} />

          {teaser && (
            <div className="mt-xsmall">
              <Text textType="html" text={teaser} />
            </div>
          )}

          {(headerLink || secondaryLink) && (
            <div className="mt-small d-none d-xl-block" data-aos={animation.links.default}>
              <div className="links">
                <LinkSwitcher
                  linkType={headerLinkType}
                  link={headerLink}
                  additionalClasses="btn-lg"
                />

                <LinkSwitcher linkType={secondaryLinkType} link={secondaryLink} />
              </div>
            </div>
          )}
        </div>

        <div className="col-10 col-xl-6">
          {video && (
            <div>
              {video.publicUrl.includes("youtube") && hasCookiebot && !parsedCookies.marketing ? (
                // <div className="cookieconsent-optout-marketing">
                <div className="alert alert-primary">
                  Bitte{" "}
                  <a href="javascript:Cookiebot.renew()">
                    {" "}
                    akzeptieren Sie unsere Marketing-Cookies{" "}
                  </a>
                  und laden Sie die Seite anschließend neu, um das Video anzusehen.
                </div>
              ) : (
                // </div>
                <VREPlayer
                  playerOptions={playerOptions}
                  resources={resources}
                  videojsOptions={videojsOptions}
                  onReady={() => {}}
                  onPlay={() => {}}
                  onPause={() => {}}
                  onEnded={() => {}}
                />
              )}
            </div>
          )}

          {(headerLink || secondaryLink) && (
            <div className="mt-small d-xl-none" data-aos={animation.links.default}>
              <div className="links">
                <LinkSwitcher
                  linkType={headerLinkType}
                  link={headerLink}
                  additionalClasses="btn-lg"
                />

                <LinkSwitcher linkType={secondaryLinkType} link={secondaryLink} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

SecondaryTAV.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default SecondaryTAV;
