import React, { useRef, useCallback } from "react";
import PropTypes from "prop-types";

import SlickSlider from "react-slick";

import Preline from "../../Atoms/Preline";
import Headline from "../../Atoms/Headline";
import Text from "../../Atoms/Text";
import Image from "../../Atoms/Image";

import { ReactComponent as Quotes } from "../../../Images/svg/quotes.svg";

const PrevArrow = (buttonProps) => {
  return (
    <span
      className={`carousel-control-prev-icon ${buttonProps.className}`}
      aria-hidden="true"
      onClick={buttonProps.onClick}
    />
  );
};

const NextArrow = (buttonProps) => {
  return (
    <span
      className={`carousel-control-next-icon ${buttonProps.className}`}
      aria-hidden="true"
      onClick={buttonProps.onClick}
    />
  );
};

/* eslint-disable react/jsx-props-no-spreading */
const StandardQuotes = ({
  properties: { header, headerType, headerDisplayStyle, subline, id, quoteItems, animation },
}) => {
  const sliderRef = useRef(null);

  const settings = useRef({
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  });

  const renderQuotes = useCallback(() => {
    return quoteItems.map((quote) => {
      return (
        <div
          key={`key_${quote.name || Math.random()}`}
          className="slider-item d-flex justify-content-center"
        >
          <div className="card w-100">
            <div className="card-body">
              <figure className="quotes-text">
                <div className="quotes-icon">
                  <Quotes />
                </div>

                <blockquote>
                  <Text textType="text" text={quote.quote} additionalClasses="color-static" />
                </blockquote>

                <figcaption>
                  {quote.name && (
                    <div className="mt-xsmall">
                      <Headline
                        headerType="h5"
                        headline={quote.name}
                        additionalClasses="color-static"
                      />
                    </div>
                  )}

                  <Preline preline={quote.title} additionalClasses="color-static" />
                </figcaption>
              </figure>
            </div>

            <div className="card-image">
              <Image image={quote.image[0]} animation={animation.image.quotes} />
            </div>
          </div>
        </div>
      );
    });
  }, [quoteItems, animation]);

  return (
    <div className="container quotes standard">
      <div className="row justify-content-center">
        <div className="col-md-10">
          <Preline preline={subline} />

          <Headline headerType={headerType} headerStyle={headerDisplayStyle} headline={header} />
        </div>
      </div>

      {quoteItems.length > 0 && (
        <div className="row mt-small">
          <div className="col-12">
            <div ref={sliderRef} id={`quotes-standard-${id}`}>
              <SlickSlider {...settings.current}>{renderQuotes()}</SlickSlider>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

StandardQuotes.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default StandardQuotes;
