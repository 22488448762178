import React from "react";
import PropTypes from "prop-types";

// eslint-disable-next-line no-unused-vars
import videojs from "video.js";
import VREPlayer from "videojs-react-enhanced";

import Preline from "../../Atoms/Preline";
import Headline from "../../Atoms/Headline";
import Text from "../../Atoms/Text";
import LinkSwitcher from "../../Atoms/Link/LinkSwitcher";

import "video.js/dist/video-js.css";

const VideoStage = ({
  properties: {
    header,
    headerType,
    headerDisplayStyle,
    headerLink,
    headerLinkType,
    secondaryLink,
    secondaryLinkType,
    subline,
    teaser,
    media,
    maxHeight,
    viewportHeight,
    animation,
  },
}) => {
  const playerOptions = {
    src: media[0].publicUrl || "",
    controls: false,
    autoplay: "muted",
    playsinline: true,
  };

  const videojsOptions = {
    fluid: false,
    loop: false,
  };

  return (
    <div
      className={`stage video ${
        viewportHeight ? "min-vh-100 pt-xlarge pb-xlarge" : "pt-xlarge pb-xlarge"
      }`.trim()}
      style={
        !viewportHeight ? { minHeight: +maxHeight || media[0]?.properties.dimensions.height } : {}
      }
    >
      <VREPlayer playerOptions={playerOptions} videojsOptions={videojsOptions} />

      <div className="container">
        {/* <div className="row justify-content-center align-items-center text-center">
          <div className="col-md-10"> */}
        <div className="row align-items-center">
          <div className="col-md-6">
            <Headline headerType={headerType} headerStyle={headerDisplayStyle} headline={header} />

            <Preline preline={subline} />

            {teaser && (
              <div className="mt-medium">
                <Text textType="html" text={teaser} />
              </div>
            )}

            {(headerLink || secondaryLink) && (
              <div className="mt-small" data-aos={animation.links.default}>
                <div className="links">
                  <LinkSwitcher
                    linkType={headerLinkType}
                    link={headerLink}
                    // additionalClasses="btn-lg rounded-pill"
                  />

                  <LinkSwitcher linkType={secondaryLinkType} link={secondaryLink} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

VideoStage.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default VideoStage;
