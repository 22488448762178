import React from "react";
import PropTypes from "prop-types";

import Headline from "../Atoms/Headline";
import DividerSwitcher from "../ContentElements/Divider/DividerSwitcher";
import Image from "../Atoms/Image";

const SimpleFooter = ({ content }) => {
  const renderSocialLinks = () => {
    return content.socialMedia.links?.map((socialMediaLink) => {
      return (
        <a
          key={`key_${socialMediaLink.link.url || Math.random()}`}
          href={socialMediaLink.link.url}
          noopener="true"
          noreferrer="true"
          className="link link-inline"
          target="_blank"
          rel="noreferrer"
        >
          <i className={`fab fa-${socialMediaLink.className || socialMediaLink.type} fa-2x`} />
        </a>
      );
    });
  };

  const renderFooterLinks = () => {
    return content.footerLinks?.map((footerLink) => {
      return (
        <a
          key={`key_${footerLink.link.url || Math.random()}`}
          className="link link-sm link-primary link-divider d-inline-block"
          href={footerLink.link.url}
        >
          {footerLink.link.title}
        </a>
      );
    });
  };

  return (
    <div className="container footer simple">
      {content.socialMedia.links.length > 0 && (
        <>
          <div className="row social-media justify-content-center mb-small">
            <div className="col-12 text-center">
              <Headline headerType="h6" headline={content.socialMedia.label} />
              {renderSocialLinks()}
            </div>
          </div>

          <div className="row mb-small">
            <div className="col-12">
              <DividerSwitcher layout="standard" />
            </div>
          </div>
        </>
      )}

      {content.logo[0] && (
        <div className="row mb-medium">
          <div className="col-12 text-center">
            <Image image={content.logo[0]} />
          </div>
        </div>
      )}

      {content.footerLinks.length > 0 && (
        <div className="row footerlinks mb-xsmall">
          <div className="col-12 text-center">{renderFooterLinks()}</div>
        </div>
      )}

      <div className="row copyright">
        <div className="col-12 text-center">
          <div className="teaser fs-7">{content.copyright}</div>
        </div>
      </div>

      {content.additionalImage?.[0] && (
        <div className="row additional-image mt-small">
          <div className="col-12">
            <Image image={content.additionalImage[0]} />
          </div>
        </div>
      )}
    </div>
  );
};

SimpleFooter.propTypes = {
  content: PropTypes.instanceOf(Object),
};

export default SimpleFooter;
