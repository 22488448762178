import React from "react";
import PropTypes from "prop-types";

import StandardAccordion from "./StandardAccordion";
import SecondaryAccordion from "./SecondaryAccordion";
import ComponentMissing from "../../Debug/ComponentMissing";

const AccordionSwitcher = ({
  layout,
  header,
  headerType,
  headerDisplayStyle,
  headerLink,
  headerLinkType,
  id,
  accordionItems,
  animation,
}) => {
  const properties = {
    header,
    headerType,
    headerDisplayStyle,
    headerLink,
    headerLinkType,
    id,
    accordionItems,
    animation,
  };

  function switchAccordion() {
    switch (layout) {
      case "standard":
        return <StandardAccordion properties={properties} />;
      case "secondary":
        return <SecondaryAccordion properties={properties} />;
      default:
        return <ComponentMissing type="Accordion" subType={layout} />;
    }
  }

  return switchAccordion();
};

AccordionSwitcher.propTypes = {
  layout: PropTypes.string,
  header: PropTypes.string,
  headerType: PropTypes.string,
  headerDisplayStyle: PropTypes.string,
  headerLink: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  headerLinkType: PropTypes.string,
  id: PropTypes.number,
  accordionItems: PropTypes.instanceOf(Array),
  animation: PropTypes.instanceOf(Object),
};

export default AccordionSwitcher;
