import React from "react";
import PropTypes from "prop-types";

import StandardTable from "./StandardTable";
import SecondaryTable from "./SecondaryTable";
import ComponentMissing from "../../Debug/ComponentMissing";

const TableSwitcher = ({ tableType, table, additionalClasses }) => {
  const properties = {
    tableType,
    table,
    additionalClasses,
  };

  function switchLink() {
    switch (tableType) {
      case "standard":
        return <StandardTable properties={properties} />;
      case "secondary":
        return <SecondaryTable properties={properties} />;
      default:
        return <ComponentMissing type="Link" subType={tableType} />;
    }
  }

  return switchLink();
};

TableSwitcher.propTypes = {
  tableType: PropTypes.string,
  table: PropTypes.instanceOf(Array),
  additionalClasses: PropTypes.string,
};

TableSwitcher.defaultProps = {
  additionalClasses: "",
};

export default TableSwitcher;
