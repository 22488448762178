import React, { useCallback } from "react";
import PropTypes from "prop-types";

import Image from "../../Atoms/Image";

const StandardOnlyImage = ({ properties: { image, maxHeight, cols, alignment } }) => {
  const inlineStyles = {
    height: +maxHeight || "auto",
    objectFit: "cover",
  };

  const getClasses = useCallback(() => {
    let classes = "row";

    switch (alignment) {
      case "right":
        classes += " justify-content-end";
        break;
      case "center":
        classes += " justify-content-center";
        break;
      default:
        classes += " justify-content-start";
    }

    return classes;
  }, [alignment]);

  return (
    <div className="container only-image standard">
      <div className={getClasses()}>
        <div className={`col-md-${cols}`}>
          <Image image={image} style={inlineStyles} />
        </div>
      </div>
    </div>
  );
};

StandardOnlyImage.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default StandardOnlyImage;
