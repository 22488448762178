import React from "react";
// import PropTypes from "prop-types";
import { ReactComponent as ScrollToTopIcon } from "../../Images/svg/chevron-up.svg";

const ScrollToTop = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  return (
    <button className="scroll-to-top" type="button" onClick={scrollToTop}>
      <div className="scroll-to-top-icon">
        <ScrollToTopIcon />
      </div>
    </button>
  );
};

ScrollToTop.propTypes = {};

export default ScrollToTop;
