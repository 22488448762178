import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import * as am4core from "@amcharts/amcharts4/core";
import am4themesAnimated from "@amcharts/amcharts4/themes/animated";

import { TranslationProvider } from "../Context/Translation";
import LayoutContainer from "./LayoutContainer";
import PageContainer from "./PageContainer";
import PageMissing from "../Components/Debug/PageMissing";
import SearchContainer from "./SearchContainer";

import {
  REACT_APP_AM_CHARTS_LICENCE,
  REACT_APP_AM_CHARTS_MAPS_LICENCE,
} from "../Constants/ChartConstants";

import { PAGE } from "../Constants/RouteConstants";

am4core.addLicense(REACT_APP_AM_CHARTS_LICENCE);
am4core.addLicense(REACT_APP_AM_CHARTS_MAPS_LICENCE);

am4core.useTheme(am4themesAnimated);

/* eslint-disable react/jsx-props-no-spreading */
const RootContainer = () => {
  return (
    <BrowserRouter>
      <TranslationProvider>
        <LayoutContainer>
          <section id="main" className="pt-xlarge">
            <Switch>
              <Route exact path="/error" component={PageMissing} />
              <Route
                exact
                path="/s"
                render={(routerProps) => (
                  <SearchContainer
                    {...routerProps}
                    key={`key_${routerProps.location.search || Math.random()}`}
                  />
                )}
              />
              <Route
                path={PAGE}
                render={(routerProps) => (
                  <PageContainer
                    {...routerProps}
                    key={`key_${routerProps.location.pathname || Math.random()}`}
                  />
                )}
              />
            </Switch>
          </section>
        </LayoutContainer>
      </TranslationProvider>
    </BrowserRouter>
  );
};

export default RootContainer;
