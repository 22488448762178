import React, { useState, useEffect, useContext, useCallback, useRef } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import qs from "qs";

import Pagination from "react-paginate";

import TranslationContext from "../../Context/Translation";
import ElementSwitcher from "../ContentElements/ElementSwitcher";

import { postApi } from "../../Utils/ApiUtils";

import { REACT_APP_SEARCH } from "../../Constants/APIConstants";

const SearchListLayout = ({ content }) => {
  const { translation } = useContext(TranslationContext);

  const location = useLocation();

  const [searchResults, setSearchResults] = useState(content);
  const [page, setPage] = useState(1);

  const settings = useRef({
    pagination: content.colPos0.find((el) => el.type === "ahoiitoolkit_searchlist").content.data
      .configuration,
    searchValue: location.state?.searchValue || location.search.split("=")[1],
    searchListUrl: location.state?.searchListUrl || REACT_APP_SEARCH,
  });

  const onPageSelect = useCallback(({ selected }) => {
    setPage(selected + 1);
  }, []);

  const getUrl = useCallback(() => {
    const activeTranslation = translation.find((lang) => lang.active === 1).twoLetterIsoCode;

    const languageUrl = activeTranslation !== "de" ? activeTranslation : "";
    const baseUrl = settings.current.searchListUrl;
    const pageUrl = page !== 1 ? `/page-${page}` : "";

    return `${languageUrl}${baseUrl}${pageUrl}`;
  }, [translation, page]);

  const loadSearchResults = useCallback(async () => {
    try {
      const { data } = await postApi(
        getUrl(),
        qs.stringify({
          search: settings.current.searchValue,
          page,
        }),
      );

      if (data.content?.colPos0?.length) {
        setSearchResults(data.content);
      }
    } catch (error) {
      console.log(error);
    }
  }, [page, getUrl]);

  useEffect(() => {
    loadSearchResults();
  }, [location.state, location.search, loadSearchResults]);

  return (
    <div className="search-list">
      <ElementSwitcher contentElements={searchResults.colPos0} />

      <div className="mt-small">
        {settings.current.pagination.maxPages > 1 && (
          <div className="container pb-small">
            <div className="row">
              <div className="col-12">
                <nav aria-label="News pagination">
                  <Pagination
                    previousLabel={<> &lsaquo;</>}
                    nextLabel={<>&rsaquo;</>}
                    breakLabel="..."
                    pageCount={settings.current.pagination.maxPages}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={3}
                    containerClassName="pagination justify-content-center"
                    pageClassName="page-item"
                    previousClassName="page-item"
                    nextClassName="page-item"
                    breakClassName="page-item"
                    pageLinkClassName="page-link"
                    previousLinkClassName="page-link"
                    nextLinkClassName="page-link"
                    breakLinkClassName="page-link"
                    activeClassName="active"
                    forcePage={settings.current.pagination.currentPage - 1}
                    onPageChange={onPageSelect}
                  />
                </nav>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

SearchListLayout.propTypes = {
  content: PropTypes.instanceOf(Object),
};

export default SearchListLayout;
