import React, { useCallback } from "react";
import PropTypes from "prop-types";

import Preline from "../../Atoms/Preline";
import Headline from "../../Atoms/Headline";
import Text from "../../Atoms/Text";
import Image from "../../Atoms/Image";
import LinkSwitcher from "../../Atoms/Link/LinkSwitcher";

const WallFeatures = ({
  properties: { header, headerType, headerDisplayStyle, subline, features, animation },
}) => {
  const renderFeatures = useCallback(() => {
    return features.map((feature) => {
      const { cols_xs: colXs, cols_sm: colSm, cols_md: colMd, cols_lg: colLg } = feature;

      return (
        <div
          key={`key_${feature.headline || Math.random()}`}
          className={`col-xs-${colXs} col-sm-${colSm} col-md-${colMd} col-lg-${colLg} d-flex`}
        >
          <div className="card w-100">
            <div className="card-body">
              <div className="card-image">
                {feature.image[0] && (
                  <div className="mb-xsmall">
                    <Image image={feature.image[0]} />
                  </div>
                )}
              </div>

              <Preline preline={feature.subheader} additionalClasses="color-static" />

              <Headline
                headerType="h5"
                headline={feature.headline}
                additionalClasses="color-static"
              />

              {feature.teaser && (
                <div className="mt-xsmall">
                  <Text textType="html" text={feature.teaser} additionalClasses="color-static" />
                </div>
              )}

              {feature.link && (
                <div className="mt-small" data-aos={animation.links.default}>
                  <div className="links">
                    <LinkSwitcher
                      linkType={feature.link_type}
                      link={feature.link}
                      additionalClasses="color-static"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    });
  }, [features, animation]);

  return (
    <div className="container features wall">
      <div className="row justify-content-center">
        <div className="col-md-10">
          <Preline preline={subline} />

          <Headline headerType={headerType} headerStyle={headerDisplayStyle} headline={header} />
        </div>
      </div>

      {features.length > 0 && (
        <div className="mt-small">
          <div className="row justify-content-center g-4">{renderFeatures()}</div>
        </div>
      )}
    </div>
  );
};

WallFeatures.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default WallFeatures;
