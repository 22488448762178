import React from "react";
import PropTypes from "prop-types";

import StandardLink from "./StandardLink";
import ButtonLink from "./ButtonLink";
import ComponentMissing from "../../Debug/ComponentMissing";

/**
 * use to render typoLinks
 * extracts destination, target and title of link
 * linktype like primaryButton, secondaryButton, text will be given as prop
 * maybe i will switch logic do extract informations to backend in next feature,
 * dont like this typo3 special code in fe
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const LinkSwitcher = ({ linkType, link, additionalClasses, onClick }) => {
  const properties = {
    linkType,
    link,
    additionalClasses,
    onClick,
  };

  function switchLink() {
    switch (true) {
      case linkType.includes("btn"):
        return <ButtonLink properties={properties} />;
      case linkType.includes("link"):
        return <StandardLink properties={properties} />;
      default:
        return <ComponentMissing type="Link" subType={linkType} />;
    }
  }

  return switchLink();
};

LinkSwitcher.propTypes = {
  linkType: PropTypes.string,
  link: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  additionalClasses: PropTypes.string,
  onClick: PropTypes.func,
};

LinkSwitcher.defaultProps = {
  additionalClasses: "",
};

export default LinkSwitcher;
