import React from "react";
import PropTypes from "prop-types";

import StandardCTA from "./StandardCTA";
import SecondaryCTA from "./SecondaryCTA";
import ComponentMissing from "../../Debug/ComponentMissing";

const CTA = ({
  layout,
  header,
  headerType,
  headerDisplayStyle,
  headerLink,
  headerLinkType,
  secondaryLink,
  secondaryLinkType,
  subline,
  animation,
}) => {
  const properties = {
    header,
    headerType,
    headerDisplayStyle,
    headerLink,
    headerLinkType,
    secondaryLink,
    secondaryLinkType,
    subline,
    animation,
  };

  function switchCTA() {
    switch (layout) {
      case "standard":
        return <StandardCTA properties={properties} />;
      case "secondary":
        return <SecondaryCTA properties={properties} />;
      default:
        return <ComponentMissing type="CTA" subType={layout} />;
    }
  }

  return switchCTA();
};

CTA.propTypes = {
  layout: PropTypes.string,
  header: PropTypes.string,
  headerType: PropTypes.string,
  headerDisplayStyle: PropTypes.string,
  headerLink: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  headerLinkType: PropTypes.string,
  secondaryLink: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  secondaryLinkType: PropTypes.string,
  subline: PropTypes.string,
  animation: PropTypes.instanceOf(Object),
};

export default CTA;
