import React from "react";
import PropTypes from "prop-types";

import StandardTAB from "./StandardTAB";
import NumberedTAB from "./NumberedTAB";
import IconsTAB from "./IconsTAB";
import ComponentMissing from "../../Debug/ComponentMissing";

const TextAndBulletsSwitcher = ({
  layout,
  header,
  headerType,
  headerDisplayStyle,
  headerLink,
  headerLinkType,
  secondaryLink,
  secondaryLinkType,
  subline,
  teaser,
  bodytext,
  mirrored,
  animation,
}) => {
  const properties = {
    header,
    headerType,
    headerDisplayStyle,
    headerLink,
    headerLinkType,
    secondaryLink,
    secondaryLinkType,
    subline,
    teaser,
    bodytext,
    mirrored,
    animation,
  };

  function switchTextAndBullets() {
    switch (layout) {
      case "standard":
        return <StandardTAB properties={properties} />;
      case "numbered":
        return <NumberedTAB properties={properties} />;
      case "icon-list":
        return <IconsTAB properties={properties} />;
      default:
        return <ComponentMissing type="Text and Bullets" subType={layout} />;
    }
  }

  return switchTextAndBullets();
};

TextAndBulletsSwitcher.propTypes = {
  layout: PropTypes.string,
  header: PropTypes.string,
  headerType: PropTypes.string,
  headerDisplayStyle: PropTypes.string,
  headerLink: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  headerLinkType: PropTypes.string,
  secondaryLink: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  secondaryLinkType: PropTypes.string,
  subline: PropTypes.string,
  teaser: PropTypes.string,
  bodytext: PropTypes.oneOfType([PropTypes.instanceOf(Array), PropTypes.string]),
  mirrored: PropTypes.string,
  animation: PropTypes.instanceOf(Object),
};

export default TextAndBulletsSwitcher;
